<template>
  <div class="container">
    <div class="card coming-soon-message">
      <h2>Des idées cadeaux à venir !</h2>
      <p>
        Merci de visiter <strong>CadeauxExpress</strong> ! Nous travaillons activement pour vous proposer des suggestions de cadeaux soigneusement sélectionnées, adaptées à toutes les occasions et à tous les budgets.
      </p>
      <p>
        Très bientôt, vous trouverez ici des inspirations uniques pour surprendre vos proches, que ce soit pour un anniversaire, une fête spéciale, ou juste pour le plaisir d'offrir.
      </p>
      <p class="highlight">
        Restez à l'écoute, les meilleures idées cadeaux arrivent bientôt !
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ComingSoon'
};
</script>

<style scoped>
.coming-soon-message {
  text-align: center;
}

.coming-soon-message h2 {
  color: var(--red-bordeaux);
  font-size: 1.5em;
  margin-bottom: 10px;
}

.coming-soon-message p {
  line-height: 1.6;
  color: var(--black-charcoal);
}

.coming-soon-message .highlight {
  color: var(--red-dark);
  font-weight: bold;
  margin-top: 10px;
}
</style>
