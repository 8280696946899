<template>
  <el-container style="height: 100vh;">
    <!-- Colonne fixe à gauche avec le logo en haut -->
    <el-aside class="aside">
      <div class="logo-container">
        <router-link to="/">
          <img src="@/assets/logo-desktop.png" alt="CadeauxExpress Logo" class="logo" />
        </router-link>
      </div>
      <div class="about">
        <p>À propos</p>
        <ul>
          <li><router-link to="/about">Mentions légales</router-link></li>
        </ul>
      </div>
    </el-aside>

    <!-- Contenu principal -->
    <el-main class="main-content">
      <router-view />
    </el-main>
  </el-container>
</template>

<script>

  export default {
  name: "App",
};
</script>

<style scoped>
/* Colonne de gauche avec largeur fixe */
.aside {
  width: 250px; /* Largeur fixe pour la colonne */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #f4f4f4;
  padding: 20px;
  border-right: 1px solid #ddd;
  box-sizing: border-box;
}

/* Contenu principal */
.main-content {
  padding: 20px;
}

/* Logo */
.logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 20px;
  a {
    text-decoration: none;
    border-bottom: none;
  }
}

.logo {
  width: 210px; /* Taille ajustée du logo */
  height: auto;
  border-radius: 5px;
}

/* À propos */
.about {
  font-size: 0.9em;
  color: #666;
}

.about ul {
  list-style-type: none;
  padding: 0;
}

.about li {
  margin-bottom: 5px;
}

</style>