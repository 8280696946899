<template>
  <div class="cookie-policy container">
    <h1>Politique de Cookies</h1>
    <p>
      Notre site utilise des cookies pour vous offrir une meilleure expérience de navigation et collecter des statistiques de visite. Vous pouvez accepter ou refuser les cookies non essentiels en fonction de vos préférences.
    </p>

    <h2>Qu'est-ce qu'un cookie ?</h2>
    <p>
      Un cookie est un petit fichier texte stocké sur votre appareil lors de votre visite. Il aide à améliorer l’expérience utilisateur et peut contenir des informations comme vos préférences ou des statistiques de visite.
    </p>

    <h2>Types de cookies utilisés</h2>
    <ul>
      <li><strong>Cookies essentiels :</strong> Nécessaires au bon fonctionnement du site, ils ne peuvent pas être désactivés.</li>
      <li><strong>Cookies de mesure d’audience :</strong> Utilisés pour analyser la fréquentation du site. Ex. : Google Analytics.</li>
      <li><strong>Cookies de préférences :</strong> Permettent de sauvegarder vos choix d'affichage et de langue.</li>
    </ul>

    <h2>Gestion des cookies</h2>
    <p>
      Vous pouvez accepter ou refuser les cookies en utilisant notre bannière de consentement. Vous pouvez également configurer votre navigateur pour refuser les cookies.
    </p>
  </div>
</template>

<script>
export default {
  name: 'LegalMentions'
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
}

h1 {
  color: var(--red-bordeaux);
  font-size: 1.8em;
  margin-bottom: 20px;
}

h2 {
  color: var(--red-dark);
  font-size: 1.4em;
  margin-top: 20px;
}

p {
  color: var(--black-charcoal);
}
</style>
