import { createRouter, createWebHistory } from 'vue-router';
import LegalMentions from '../views/LegalMentions.vue';
import Cookies from "@/views/Cookies.vue";
import Home from "@/views/Home.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'LegalMentions',
        component: LegalMentions
    },
    {
        path: '/about/cookies',
        name: 'Cookies',
        component: Cookies
    }
];

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
