import { createStore } from 'vuex';

const store = createStore({
    state: {
        searchTerm: '',
        products: []
    },
    mutations: {
        setSearchTerm(state, term) {
            state.searchTerm = term;
        },
        setProducts(state, products) {
            state.products = products;
        }
    },
    actions: {
        async fetchProducts({ commit, state }) {
            try {
                // Appel à l'API pour obtenir les produits en fonction du terme de recherche
                const response = await fetch(`https://api.example.com/products?search=${state.searchTerm}`);
                const data = await response.json();
                commit('setProducts', data.products); // Mettre à jour l'état avec les produits
            } catch (error) {
                console.error('Erreur lors de la récupération des produits:', error);
            }
        }
    },
    getters: {
        allProducts(state) {
            return state.products;
        }
    }
});

export default store;