<template>
  <div class="legal-mentions container">
    <h1>Mentions Légales</h1>
    <p>
      Bienvenue sur CadeauxExpress. Cette page contient les mentions légales relatives à notre site. Merci de les lire attentivement.
    </p>

    <h2>Éditeur du Site</h2>
    <p>
      Nom de l'entreprise : Paul TECCHIO<br />
      Email : contact@cadeauxexpress.com<br />
      Numéro SIRET : 792 867 699 00052<br />
    </p>

    <h2>Hébergement</h2>
    <p>
      Hébergeur : OVH SAS<br />
      Adresse de l'hébergeur : 2 rue Kellermann, 59100 Roubaix, France<br />
      Téléphone de l'hébergeur : 09 72 10 10 07
    </p>

    <h2>Responsabilité</h2>
    <p>
      CadeauxExpress ne peut être tenu responsable des dommages directs ou indirects résultant de l'accès au site, ou de l'utilisation des informations qu'il présente.
    </p>

    <h2>Propriété Intellectuelle</h2>
    <p>
      Tous les contenus présents sur ce site (textes, images, logos) sont protégés par les droits de propriété intellectuelle et appartiennent à leurs propriétaires respectifs.
    </p>

    <h2>Protection des données personnelles</h2>
    <p>
      Conformément à la réglementation, vous disposez d'un droit d'accès, de modification et de suppression de vos données personnelles. Pour exercer ce droit, contactez-nous à l'adresse suivante : [Votre adresse e-mail de contact].
    </p>

    <h2>Cookies</h2>
    <p>
      Nous utilisons des cookies pour améliorer votre expérience. <router-link to="/about/cookies">En savoir plus</router-link>
    </p>
  </div>
</template>

<script>
export default {
  name: 'LegalMentions'
};
</script>

<style scoped>
.container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  line-height: 1.6;
}

h1 {
  color: var(--red-bordeaux);
  font-size: 1.8em;
  margin-bottom: 20px;
}

h2 {
  color: var(--red-dark);
  font-size: 1.4em;
  margin-top: 20px;
}

p {
  color: var(--black-charcoal);
}
</style>
